<script setup lang="ts">
import { subject } from '@casl/ability';
import { type IntegrationLink, type Trigger } from '@respell/database';
import integrations from '@respell/integrations';
import type { Integration } from '@respell/integrations/definition';
import type { GraphNode } from '@vue-flow/core';
import { formatDistanceToNow } from 'date-fns';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';
import PostAuthModal from '~/components/modals/PostAuthModal.vue';
import PreAuthModal from '~/components/modals/PreAuthModal.vue';
import { useIntegrationStore } from '~/stores/integrations';

const props = defineProps<{
  details: Integration;
  step?: GraphNode;
  trigger?: Trigger;
}>();

const integrationStore = useIntegrationStore();
const canvasStore = useCanvasStore();
const userStore = useUserStore();
const workspaceStore = useWorkspaceStore();

const modal = useModal();

const { can } = useAppAbility();
const { linkedAccounts } = storeToRefs(integrationStore);
const { subscription } = storeToRefs(workspaceStore);

const isGated = computed(
  () =>
    [
      'google-sheet',
      'google-calendar',
      'gmail',
      'bland',
      'google-drive',
    ].includes(props.details.key) &&
    !can('dataSources', subject('Subscription', subscription.value)),
);

const details = computed(() => props.details);

const availableAccount = computed(() =>
  linkedAccounts.value.find(
    (account: IntegrationLink) => account.service === details.value?.key,
  ),
);

const linkedAccount = computed(() =>
  props.step
    ? props.step?.data.integrations?.find(
        (account: IntegrationLink) => account.service === details.value?.key,
      )
    : props.trigger && props.trigger.integration?.service === details.value?.key
      ? props.trigger.integration
      : null,
);

const isConnected = computed(() =>
  props.step || props.trigger ? linkedAccount.value : availableAccount.value,
);

const isOwner = computed(() => {
  return linkedAccount.value
    ? linkedAccount.value?.userId === userStore.user?.id
    : true;
});

async function connect() {
  if (availableAccount.value) {
    if (props.step) {
      canvasStore.linkStep(availableAccount.value, props.step.id);
    } else if (props.trigger) {
      canvasStore.linkTrigger(availableAccount.value);
    }
  } else if (details.value.options?.length) {
    modal.open(PreAuthModal, {
      details: details.value,
      async onConfirm() {
        setTimeout(async () => {
          await completeConnection();
        }, 1000);
      },
    });
  } else {
    await integrationStore.connect(
      details.value?.key,
      {},
      details.value.metadataOverride ?? {},
    );
    await completeConnection();
  }
}

async function disconnect() {
  if (props.step) {
    canvasStore.unLinkStep(linkedAccount.value.id, props.step.id);
  } else if (props.trigger) {
    canvasStore.unlinkTrigger();
  } else if (availableAccount.value) {
    modal.open(ConfirmModal, {
      action: 'disconnect',
      type: details.value.name,
      message: 'Doing so will remove this integration from all spells.',
      isDangerous: true,
      async onConfirm() {
        await integrationStore.disconnect(availableAccount.value.id);
      },
    });
  }
}

async function handleDisconnect() {
  if (isOwner.value) {
    await disconnect();
  } else {
    modal.open(ConfirmModal, {
      action: 'disconnect',
      type: 'account',
      message:
        'Doing so will disconnect the existing account for everyone using this spell',
      isDangerous: true,
      async onConfirm() {
        await disconnect();
        modal.close();
      },
    });
  }
}

async function handleConnect() {
  if (!isConnected.value) {
    await connect();
  }
}

async function completeConnection() {
  if (details.value.postAuthOptions?.length) {
    modal.open(PostAuthModal, {
      details: details.value,
      availableAccount: availableAccount.value,
      linkedAccount: props.trigger?.integration,
      async onConfirm() {
        await connect();
      },
    });
  } else {
    await connect();
  }
}

// Watch for available account and link it to the trigger
watch([availableAccount, details], () => {
  if (props.trigger && !linkedAccount.value) {
    canvasStore.linkTrigger(availableAccount.value);
  }
});
</script>
<template>
  <UTooltip
    :prevent="!isGated"
    text="A team plan is required to connect this integration."
    class="w-full"
  >
    <UButton
      v-if="details"
      size="xl"
      :color="isConnected ? 'gray' : 'white'"
      block
      :disabled="isGated"
      :ui="{ rounded: 'rounded-lg', padding: { xl: 'p-5' } }"
      @click="handleConnect"
    >
      <template #leading>
        <UIcon
          :name="integrations[details.key]?.icon"
          class="rounded-md text-5xl border-gray-50 outline border outline-gray-200 bg-transparent"
        />
      </template>
      <div class="flex flex-col items-start px-s">
        <p class="title">{{ details.name }}</p>
        <div v-if="isConnected" class="flex flex-row gap-1">
          <UIcon
            name="i-ph-check-circle-fill"
            class="w-4 h-4 text-primary-500"
          />
          <p v-if="linkedAccount" class="caption dimmed">
            Connected to {{ linkedAccount.user?.profile?.fullName }}'s account
          </p>
          <p v-else-if="availableAccount" class="caption dimmed">
            Connected {{ formatDistanceToNow(availableAccount.createdAt) }} ago
          </p>
        </div>
        <p v-else class="caption dimmed">Click to connect</p>
      </div>
      <template #trailing>
        <UTooltip
          :text="`${isConnected ? 'Disconnect' : 'Connect'} ${details.name}`"
          class="ml-auto"
        >
          <UIcon
            v-if="isConnected"
            name="i-ph-trash-simple"
            class="text-gray-400 cursor-pointer text-2xl hover:text-gray-500 transition-colors"
            @click.stop="handleDisconnect"
          />
          <UIcon v-else name="i-ph-caret-right" class="w-5 h-5" />
        </UTooltip>
      </template>
    </UButton>
  </UTooltip>
</template>
